<template>
  <div>
    <div class="horiz">
      <div class="vert hundret">

        <!-- KATEGORIE -->
        <div
          :class="($vuetify.breakpoint.smAndDown) ? 'vert biggap' : 'horiz biggap'"
          :style="($vuetify.breakpoint.smAndDown) ? '' : 'padding: 0 30px'"
        >
          <!-- Linke Seite: Eintragung -->
          <div
            class="vert-block fifty admin-edit-create"
            :style="($vuetify.breakpoint.smAndDown) ? 'width: 100%' : ''"
          >
            <!-- Bearbeiten/Hinufügen Buttons -->
            <div class="horiz gap admin-btn-container">
              <v-btn
                dark
                outlined
                :disabled="is_create_mode"
                color="white"
                :style="(!is_create_mode) ? 'background-color: #1976D2' : 'background-color: transparent'"
                @click="is_create_mode = false"
              >
                Bearbeiten
              </v-btn>
              <v-btn
                dark
                outlined
                color="white"
                :style="(is_create_mode) ? 'background-color: #1976D2' : 'background-color: transparent'"
                @click="discardCategoryClicked()"
              >
                Neue Kategorie
              </v-btn>
            </div>

            <!-- Eingabefelder -->
            <v-text-field
              label="Kategorienname"
              v-model="category_name"
              background-color="#242424"
              dark
              @input="updateSlug(true)"
            />
            <div class="horiz gap">
              <v-text-field
                label="Slug"
                v-model="category_slug"
                background-color="#242424"
                dark
                @input="updateSlug()"
                style="width:75%"
              />
              <v-select
                label="Sprache"
                v-model="category_language"
                :items="languages"
                background-color="#242424"
                dark
                hide-details
                style="flex-shrink:100;"
              />
            </div>
            <v-textarea
              label="Beschreibung"
              v-model="category_description"
              background-color="#242424"
              dark
            />

            <!-- Abbrechen/Löschen, Hinzufügen/Änderungen speichern -->
            <div class="horiz gap admin-btn-container">
              <v-btn
                v-if="!is_create_mode"
                color="red darken-1"
                outlined
                @click="showDeleteCategoryOverlay()"
              >
              <!-- @click="deleteCategory()" -->
                <v-icon
                  dark
                  small
                  left
                >
                  mdi-alert
                </v-icon>
                Löschen
              </v-btn>
              <v-btn
                color="red darken-1"
                outlined
                @click="discardCategoryClicked()"
              >
                Abbrechen
              </v-btn>

              <v-btn
                v-if="is_create_mode"
                dark
                outlined
                @click="createCategory()"
              >
                Hinzufügen
              </v-btn>
              <v-btn
                v-else
                dark
                outlined
                @click="updateCategory()"
              >
                Änderungen speichern
              </v-btn>
            </div>
            <p class="hint" v-if="is_show_hint">
              Eine Kategorie braucht immer einen Kategorienamen, eine Slug und eine Sprache!
            </p>
          </div>

          <!-- Rechte Seite: Auswählen -->
          <div
            class="vert-block fifty admin-list"
            :style="($vuetify.breakpoint.smAndDown) ? 'width: 100%; margin: 0;' : ''"
          >
            <div class="horiz gap" style="height: 54px;">
              <v-select
                class="admin-table-dropdown"
                v-model="category_table_language"
                clearable
                :items="languages"
                label="Sprache"
                background-color="#242424"
                dark
                @change="category_language=category_table_language"
              />
              <v-btn
                dark
                icon
                outlined
                :disabled="is_create_mode || !category_table_language"
                @click="moveCategory(true, current_category)"
              >
                <v-tooltip
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-arrow-up-bold
                    </v-icon>
                  </template>
                  <span>nach Vorne ziehen</span>
                </v-tooltip>
              </v-btn>

              <!-- move order_index down -->
              <v-btn
                dark
                icon
                outlined
                :disabled="is_create_mode || !category_table_language"
                @click="moveCategory(false, current_category)"
              >
                <v-tooltip
                  bottom
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-arrow-down-bold
                    </v-icon>
                  </template>
                  <span>nach Hinten schieben</span>
                </v-tooltip>
              </v-btn>

              <!-- publish/hide -->
              <div class="admin-publish-select">
                <v-select
                  v-model="category_published"
                  :items="['draft', 'public', 'pro-user']"
                  :disabled="is_create_mode"
                  background-color="#242424"
                  dark
                  hide-details
                  @change="showPublishCategoryOverlay(current_category, category_published)"
                />
              </div>
              <!-- <v-btn
                dark
                icon
                outlined
                :disabled="is_create_mode"
                @click="showPublishCategoryOverlay(current_category)"
              >
                <v-tooltip
                  bottom
                  v-if="current_category.published == 'public'"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-close
                    </v-icon>
                  </template>
                  <span>verstecken</span>
                </v-tooltip>
                <v-tooltip
                  bottom
                  v-else
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-check
                    </v-icon>
                  </template>
                  <span>publizieren</span>
                </v-tooltip>
              </v-btn> -->
            </div>

            <v-text-field
              label="Suche"
              class="admin-table-search"
              v-model="search_term"
              clearable
              background-color="#242424"
              dark
            />

            <!-- Eigentliche Tabelle -->
            <v-data-table
              :headers="category_headers"
              :items="categories_computed"
              :items-per-page="10"
              class="elevation-1"
              dark
              single-select
              sort-by="order_index"
              @click:row="setCurrentCategory"
            >
              <!-- Move -->
              <template v-slot:item.move="{ item }">
                <div
                  class="vert"
                  v-if="category_table_language"
                >
                  <div
                    @click="moveCategory(true, item)"
                    class="btn-active-up"
                  >
                    <MenuUp
                      class="admin-move-btn move-up"
                    />
                  </div>
                  <div
                    @click="moveCategory(false, item)"
                    class="btn-active-down"
                  >
                    <MenuDown
                      class="admin-move-btn move-down"
                    />
                  </div>
                </div>
                <div
                  class="vert"
                  v-else
                >
                  <div>
                    <MenuUp
                      class="admin-move-btn-disabled btn-disabled move-up"
                    />
                  </div>
                  <div>
                    <MenuDown
                      class="admin-move-btn-disabled btn-disabled move-down"
                    />
                  </div>
                </div>
              </template>
              <!-- Publish -->
              <template v-slot:item.published="{ item }">
                <!-- <v-btn
                  dark
                  icon
                  @click="showPublishCategoryOverlay(item)"
                >
                  <v-icon
                    v-if="item.published == 'public'"
                  >
                    mdi-check
                  </v-icon>
                  <v-icon
                    v-else
                  >
                    mdi-close
                  </v-icon>
                </v-btn> -->
                  <div v-if="item.published == 'public' || item.published == 'pro-user'" style="position: relative;">
                    <v-icon>
                      mdi-check
                    </v-icon>
                    <p v-if="item.published == 'pro-user'" style="position: absolute; bottom: -4px; right: 0px;">
                      +
                    </p>
                  </div>
                  <v-icon v-else>
                    mdi-close
                  </v-icon>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </div>
    <WarningOverlay
      v-if="is_show_warning_overlay"
      :warning_text="warning.text"
      :warning_icon="warning.icon"
      @cancel="resetWarning(true)"
      @confirm="confirmWarning()"
    />
  </div>
</template>

<script>
import MenuUp from '@/components/SVG/MenuUp.component.vue';
import MenuDown from '@/components/SVG/MenuDown.component.vue';
import { ArticleDate } from '@/mixins/ArticleDate.mixin.js';
import { Ultra } from '@/mixins/Ultra.mixin.js';
import { Knowledge } from '@/mixins/Knowledge.mixin.js';
import { Navigation } from '@/mixins/Navigation.mixin.js';
import { WarningOverlayMixin } from '@/mixins/WarningOverlayMixin.mixin.js';

export default {
  components: {
    MenuUp,
    MenuDown,
  },

  mixins: [
    ArticleDate, Ultra, Knowledge,
    Navigation, WarningOverlayMixin,
  ],

  props: [
    'languages',
  ],

  computed: {
    categories_computed() {
      let ret;
      if(this.category_table_language) {
        ret = this.getUObjectsFiltered(
          {
            type: 'categories',
            filter: {
              language: this.category_table_language
            }
          }).sort((a, b) => a.order_index - b.order_index);
      }
      else {
        ret = this.getUObjects({ type: 'categories' });
      }
      if(this.search_term) {
        ret = ret.filter(c => c.name.toLowerCase().includes(this.search_term.toLowerCase()));
      }
      return ret;
    },

    is_category_changed() {
      let is_changed =
        this.current_category.name != this.category_name ||
        this.current_category.slug != this.category_slug ||
        this.current_category.language != this.category_language ||
        this.current_category.description != this.category_description
      ;
      return is_changed && !this.is_create_mode;
    },
  },

  data: () => ({
    search_term: '',

    is_create_mode: true,
    current_category: { },
    category_name: '',
    category_slug: '',
    category_language: '',
    category_description: '',
    category_table_language: '',
    category_published: '',

    category_headers: [
      // { text: 'Index', value: 'order_index', width: '100px' },
      { text: '', value: 'move', width: '30px' },
      { text: 'Kategorie', value: 'name' },
      { text: 'Slug', value: 'slug', /*width: '220px'*/ },
      { text: 'Sprache', value: 'language', width: '95px', align: 'end' },
      { text: 'Count', value: 'articles.length', width: '85px', align: 'end' },
      { text: 'Publiziert', value: 'published', width: '103px', align: 'end'  },
    ],

    is_show_hint: false,
  }),

  methods: {
    createCategory() {
      if(this.category_name && this.category_slug && this.category_language) {
        this.createUObject({
          url: [
            { categories: '' },
            { showWithEntitiesNormalized: '', }
          ],
          u_object: {
            name: this.category_name.trim(),
            slug: this.category_slug,
            language: this.category_language,
            description: this.category_description,
            order_index: this.categories_computed.length,
          },
        }).then(() => {
            this.resetCurrentCategory();
          }
        )
      }
      else {
        this.is_show_hint = true;
      }
    },

    updateCategory() {
      // we only want to update, if there are actual updates on current_category
      if(this.category_name && this.category_slug && this.category_language) {
        let update = {};
        if(this.current_category.name != this.category_name.trim()) {
          update.name = this.category_name.trim();
        }
        if(this.current_category.slug != this.category_slug) {
          update.slug = this.category_slug;
        }
        if(this.current_category.language != this.category_language) {
          update.language = this.category_language;
        }
        if(this.current_category.description != this.category_description) {
          update.description = this.category_description;
        }

        if(Object.keys(update).length > 0) { // if there are any updates
          this.updateUObject({
            url: [
              { categories: this.current_category.id, },
            ],
            update: update,
          })
        }
      }
      else {
        this.is_show_hint = true;
      }
    },

    deleteCategory() {
      // every article needs a category, category delete cascades
      let children_ids = this.current_category.articles;
      for(let i = 0; i < children_ids.length; i++) {
        this.deleteUDObject({ // was previously this.deleteUObject
          url: [
            { categories: this.current_category.id },
            { articles: children_ids[i] },
          ],
        });
      }

      this.deleteUObject({
        url: [
          { categories: this.current_category.id },
        ],
      }).then(() => {
          this.resetCurrentCategory();
        }
      )
    },

    moveCategory(is_up, cat) {
      let index = this.categories_computed.findIndex((element) => element.id === cat.id);
      if((index <= 0 && is_up) || (index >= this.categories_computed.length - 1) && !is_up) {
        return;
      }
      let new_order_index = (is_up) ? index - 1 : index + 1;
      console.log(new_order_index);

      let swap_index = this.categories_computed.findIndex((element) => element.order_index === new_order_index);
      let swap_category = this.getUObject({ type: 'categories', id: this.categories_computed[swap_index].id });

      this.updateUObject({
        url: [
          { categories: cat.id },
        ],
        update: { order_index: new_order_index },
      });

      this.updateUObject({
        url: [
          { categories: swap_category.id },
        ],
        update: { order_index: index },
      });
    },

    publishCategory(cat, published_state) {
      let pub = published_state;

      this.updateUObject({
        url: [
          { categories: cat.id, },
        ],
        update: { published: pub },
      });
    },

    // gets called when a category name is set
    // slug should have a name similar to category name
    updateSlug(is_from_category_name) {
      if(is_from_category_name) {
        this.category_slug = this.category_name;
      }
      this.category_slug = this.category_slug.toLowerCase().trim();
      this.category_slug = this.category_slug.replace(/\s/g, '-');
      this.category_slug = this.category_slug.replace(/[-]+/g, '-');
      this.category_slug = this.category_slug.replace(/[ß]/g, 'ss');
      this.category_slug = this.category_slug.replace(/[öÖ]/g, 'oe');
      this.category_slug = this.category_slug.replace(/[äÄ]/g, 'ae');
      this.category_slug = this.category_slug.replace(/[üÜ]/g, 'ue');
      this.category_slug = this.category_slug.replace(/[^a-zA-Z0-9-]*/g, '');
    },

    // gets called when category row item in data-table is clicked
    setCurrentCategory(category, row) {
      this.current_category = category;
      row.select(true);

      this.category_name = category.name;
      this.category_slug = category.slug;
      this.category_language = category.language;
      this.category_description = category.description;
      this.is_create_mode = false;
      this.is_show_hint = false;
      this.category_published = category.published;
    },

    resetCurrentCategory() {
      this.is_create_mode = true;
      this.is_show_hint = false;

      this.current_category = { };
      this.category_name = '';
      this.category_slug = '';
      this.category_language = '';
      this.category_description = '';
      this.category_published = '';
    },

    discardCategoryClicked() {
      if(this.is_category_changed) {
        this.showDiscardCategoryChangesOverlay();
      }
      else {
        this.resetCurrentCategory();
      }
    },
  },
}
</script>

<style lang="css" scoped>
.v-btn {
  letter-spacing: normal;
  text-transform: none;
}
.vert {
  display: flex;
  flex-direction: column;
}
.vert-block {
  display: block;
  flex-direction: column;
}
.horiz {
  display: flex;
  flex-direction: row;
}
.hundret {
  width: 100%;
}
.gap {
  grid-row-gap: 10px;
  grid-column-gap: 10px;
}
.biggap {
  grid-row-gap: 30px;
  grid-column-gap: 30px;
}
.giantgap {
  grid-row-gap: 50px;
  grid-column-gap: 50px;
}
.admin-head {
  cursor: pointer;
}
.admin-edit-create {
  width: 450px;
  flex-shrink: 0;
}
.admin-btn-container {
  justify-content: flex-end;
  flex-wrap: wrap;
}
.admin-list {
  margin: 0 20px;
  flex-grow: 1;
}
.admin-thumbnail {
  justify-content: space-between;
  padding: 4px 0 24px 0;
}
.admin-table-dropdown {
  padding-top: 0;
  margin-top: 0;
  max-width: 300px;
}
.admin-table-search {
  padding-top: 0;
  margin-top: 0;
  max-width: 440px;
}
.admin-publish-select {
  max-width: 95px;
}
.admin-publish-select > .v-text-field {
  padding: 0;
  margin: 0;
}
h1 {
  padding-bottom: 20px;
}
.admin-move-btn, .admin-move-btn-disabled {
  height: 5px;
}
.btn-disabled {
  opacity: .33;
}
.btn-active-up, .btn-active-down {
  opacity: .67;
}
.btn-active-up:hover, .btn-active-down:hover {
  opacity: 1;
  cursor: pointer;
}
.move-up {
  transform: translateY(100%)
}
.move-down {
  transform: translateY(-100%)
}
</style>
